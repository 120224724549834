import type { BrandingGeneral } from '~/types/branding'

export function useCompanyCustomCss() {
  const applyCustomCss = (brandingGeneral: BrandingGeneral) => {
    const { custom_css: css } = brandingGeneral

    if (css) useHead({ link: [{ rel: 'stylesheet', href: css }] })
  }

  return { applyCustomCss }
}
